<template>
	<div class="section-1">
		<div class="container-section">
			<div class="content-text">
				<div class="center-on-div">
					<h1 class="primary-title">TBF Digital<br/>Simplifică<br/>Managementul</h1>
					<div class="try-free">
						<button class="try-free-btn" @click="openPricing">Vezi abonamentele disponibile <icon-arrow class="icon-arrow right"/></button>
					</div>
				</div>
			</div>

			<div class="image-animated">
				<svg-section-1 class="svg-section-1"/>
			</div>
		</div>
	</div>
</template>

<script>
	import IconArrow from '../Icons/Arrow'
	import SvgSection1 from '../../assets/landing/SvgSection1'


	export default {
		components: {
			IconArrow,
			SvgSection1
		},
		methods:{
			openPricing(){
				// window.open(this.$router.resolve({name: 'pricing'}).href, '_blank')
				this.$router.push({name: 'pricing'})
			}
		}
	}
</script>